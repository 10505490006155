<template>
  <div
    class="bigImg"
    @wheel="scroll($event)"
    @mousemove="mouseover($event)"
    @mouseup="mouseup($event)"
  >
    <div class="tou">
      <!--  放大 -->
      <el-icon :size="30" color="white" @click="amplification"
        ><CirclePlus />
      </el-icon>
      <!-- 缩小 -->
      <el-icon :size="30" color="white" @click="reduce"><Minus /></el-icon>
      <!-- 复原 -->
      <el-icon :size="30" color="white" @click="restore"
        ><FullScreen
      /></el-icon>
      <!-- 向右旋转 -->
      <el-icon :size="30" color="white" @click="right"
        ><RefreshRight
      /></el-icon>
      <!-- 向左旋转 -->
      <el-icon :size="30" color="white" @click="left"><RefreshLeft /></el-icon>
      <!-- 取消预览 -->
      <el-icon :size="30" color="white" @click="box"><CloseBold /></el-icon>
    </div>

    <div
      class="img"
      ref="img"
      @mousedown="click($event)"
      :style="{
        transform: 'scale( ' + S + ' ) rotate( ' + R + 'deg)',
        left: X + 'px',
        top: Y + 'px',
        backgroundImage: `url(${message.src})`,
      }"
    ></div>
    

  
  </div>
</template>

<script setup>
import {
  CirclePlus,
  Minus,
  FullScreen,
  RefreshRight,
  RefreshLeft,
  CloseBold,
} from "@element-plus/icons-vue";
// 点击图片预览
import { ref, defineProps, defineEmits,onMounted } from "vue";
const img = ref(null);
const emits = defineEmits(["son"]);
defineProps({
  message: Object,
});

onMounted( ()=>{
    math()
} )

const box1 = ref();
const box2 = ref();



const box = () => {
  emits("son", {
    divValue: true,
    bigImgValue: false,
  });
};

const S = ref(1);

// 动态获取偏移量，让预览盒子居中
const math = () => {
//    console.log(img.value.offsetLeft + '111111');
    // box1.value = img.value.offsetLeft 
    box1.value = (window.innerWidth / 2) - ( img.value.offsetWidth / 2 )
    box2.value = img.value.offsetTop
    X.value =  box1.value ;
//   Y.value = box2.value ;
    console.log( window.innerWidth );
    console.log( img.value.offsetWidth );

};

window.addEventListener("resize", math);

// 点击放大
const amplification = () => {
  S.value = S.value + 0.1;

  if (S.value >= 10) {
    S.value = 10;
  }


};
// 复原
const restore = () => {
  S.value = 1

  R.value = 0;
  X.value =  box1.value ;
  Y.value = box2.value ;

};

// 缩放
const scroll = (e) => {
  // 通过 e.deltaY 来判断是向上还是向下划，向上是正值，向下是负值，从而进行缩大缩小

  if (e.deltaY > 0) {

    S.value = S.value + 0.1;

    if (S.value >= 10) {
    S.value = 10;
  }

  } else if (e.deltaY < 0) {

    S.value = S.value - 0.1;

    if (S.value <= 0.5) {
      S.value = 0.5;
    }

  }
};

// 缩小
const reduce = () => {
  S.value = S.value - 0.1;

  if (S.value <= 0.5) {
    S.value = 0.5;
  }
};

const R = ref(0);

// 向右旋转
const right = () => {
  R.value = R.value + 90;
};
// 向左旋转
const left = () => {
  R.value = R.value - 90;
};



// 拖动图片
const X = ref();
const Y = ref();
const x = ref();
const y = ref();

const down = ref(false); // 判断鼠标是否按下
const click = (e) => {
  down.value = true;

  x.value = e.pageX - img.value.offsetLeft;
  y.value = e.pageY - img.value.offsetTop;
};
const mouseover = (e) => {


  if (down.value) {
    console.log(img.value.offsetLeft );
    X.value = e.pageX - x.value;
    Y.value = e.pageY - y.value;
  }
};
const mouseup = () => {
  // console.log(e.target);
  down.value = false;
};
</script>

<style  scoped>
.tou {
  top: 70px;
  position: fixed;
  display: flex;
  width: 300px;
  height: 50px;
  justify-content: space-between;
  background: #1d0101;
  border-radius: 20px;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  /* 禁止双击浏览器，全选 */
  -webkit-user-select: none; /* Chrome, Safari, Opera */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
  z-index: 9999;
}

.bigImg {
  z-index: 99;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  place-items: center;
  background: #000000aa;
  overflow: hidden;
}
.bigImg .img {
  position: absolute;
  /* pointer-events: none; 要禁止鼠标拖动浏览器中的图片 */
  z-index: 999;
  width: 620px;
  height: 450px;
  /* transition: all 0.3s ; */
  /* object-fit: scale-down; */
  /* 给缩放 放大做限制 */

  background-size: 100%;
  /* background-repeat: no-repeat; */

  background-position: center center;
}
</style>