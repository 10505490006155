<template>
         <img src="https://m.lclk.top/images/d5b421461c8daf805da60fad68b8152b" alt="">
          <div @click="fun"  @dragover="gover" @drop="drop" @dragenter="genter" @dragleave="leave">
            <input type="file" name="" id="" ref="inp">
            </div>
</template>

<script setup>
import { ref } from 'vue'

const inp = ref( null )

const fun = ()=>{
  console.log(inp.value);
  //  inp.value.click()
}
// 默认元素是不可以被拖拽的，通过给元素添加 draggable="true" 属性，让元素变为可以被拖拽
// 默认元素 是不可以接受拖拽元素的，所以需要通过事件对象 e.preventDefault()，阻止该默认行为，让元素变为可以接受拖拽元素 input中的files 可以接受拖拽元素
// 通过以下事件，实现拖拽过程中的一些操作
// 这些事件可以两类使用：
// 拖拽元素上使用：ondragstart
// 接收元素上使用：ondragover  ondrop  ondragenter  ondragleave

// ondragstart事件：当拖拽操作开始时触发。可以在该事件处理程序中设置拖拽时传输的数据。

const leave = ()=>{ // 当拖拽元素，离开接收元素时，触发
  console.log('当拖拽元素，离开时，触发');
}

const genter = (e)=>{ // 拖拽元素进入接收元素的可放置区域时触发
  console.log('被拖拽元素进入目标元素的可放置区域时触发');
  e.preventDefault(); 
 
}

// 拖拽元素，在接收元素的上方时，触发，只要一直在上方，该事件就会一直被触发
const gover = (e)=>{ 
  e.preventDefault(); // 阻止默认行为，让接收元素，可以接受拖拽元素
  console.log('拖拽元素，在接受元素的上方时，触发');
}

// 当拖拽元素，在接受元素上释放时触发
const drop = (e)=>{ 
  e.preventDefault(); // 阻止默认行为，让该元素，接受被拖拽元素

  // 通过事件对象中的 e.dataTransfer 对象，得到拖拽元素的信息
  console.log(e.dataTransfer.types);  // 拖拽对象的类型
  console.log(e.dataTransfer.files); // 拖拽对象的文件

 console.log('当拖拽元素，在接受元素上释放时触发');
  inp.value.files = e.dataTransfer.files
  console.log(inp.value.files);
  
}
</script>

<style lang="scss" scoped>
div{
  width: 200px;
  height: 200px;
  border: 1px solid red;
  
}
input{
 
 display: none;
}

</style>

