import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView'
import addView from "../views/addView";
import VueQuill from "../components/VueQuill";
import Vuesb from '../views/sbView'
import VueDialog from '../components/VueUploader'
import VueMore from "../components/VueMore";

const routes = [
  {
    path: '/',
    component: HomeView
  },

  {
    path: '/addView',
    component: addView
  },
  {
    path: '/VueQuill',
    component: VueQuill
  },
  {
    path: '/Vuesb',
    component: Vuesb
  },
  {
    path: '/VueDialog',
    component: VueDialog
  },
  {
    path: '/VueMore',
    component: VueMore
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
