<!-- <template>

        <div class="box" v-for="(index) in 5" :key="index" @touchmove="fun($event)">
            <div  class="noe"> {{ index }} </div>
            <div class="noe1" :style="{  flex:flex }" ></div>
            <div class="noe2" :style="{  flex:flex }"></div>
        </div>

</template>

<script setup>
import { ref } from 'vue';
const flex = ref(0);
const fun=(e)=>{
    // flex.value = 2
    // console.log( flex.value++);
 
    console.log(e.changedTouches[0].screenX);
    console.log('触发');
}
</script>

<style scoped>
    .box{
        width: 100%;
        height: 100px;
        display: flex;
        overflow: auto;
        margin-top: 10px;
    }
    .noe{
       flex: 7;
      background: #12d071;
    }
    .noe1{
        flex: 0;
        background: #db0101;
    }
    .noe2{
        flex: 0;
        background: #685e5e;
    }
</style> -->

<template>
    <div class="item-list">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="item"
        @touchstart="onTouchStart(index, $event)"
        @touchmove="onTouchMove($event)"
        @touchend="onTouchEnd(index)"
      >
        {{ item.text }}
        <button v-show="item.showDelete" @click="deleteItem(index)">删除</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        items: [
          { text: 'Item 1', startX: 0, dist: 0, showDelete: false },
          { text: 'Item 2', startX: 0, dist: 0, showDelete: false },
          // 其他项目
        ]
      };
    },
    methods: {
      onTouchStart(index, event) {
        this.items[index].startX = event.touches[0].clientX;
        this.items[index].dist = 0;
        this.resetDeleteButtons(index);
      },
    //   onTouchMove(event) {
    //     // 可以根据需要处理滑动过程中的逻辑
    //   },
      onTouchEnd(index) {
        if (this.items[index].dist < -30) {
          this.items[index].showDelete = true;
        } else {
          this.items[index].showDelete = false;
        }
      },
      deleteItem(index) {
        this.items.splice(index, 1);
      },
      resetDeleteButtons(currentIndex) {
        this.items.forEach((item, index) => {
          if (index !== currentIndex) {
            item.showDelete = false;
          }
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .item-list {
    width: 100%;
  }
  
  .item {
    position: relative;
    height: 50px;
    line-height: 50px;
    background-color: #f3f3f3;
    border-bottom: 1px solid #ccc;
    overflow: hidden;
  }
  
  button {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 100%;
    background-color: red;
    color: #fff;
    transform: translateX(100%);
    transition: transform 0.3s;
  }
  
  button:hover {
    transform: translateX(0);
  }
  </style>
  