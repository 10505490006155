<template>

  <router-view></router-view>

</template>

<style lang="scss">
*{
  margin: 0;
  padding: 0;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif,微软雅黑;
  /* 自定义滚动条样式 */
/* 宽度和高度可以根据需要调整 */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
/* 滑块 */
::-webkit-scrollbar-thumb {
    background-color: white; /* 与页面背景色相同 */
}
/* 轨道 */
::-webkit-scrollbar-track {
    background-color: white; /* 与页面背景色相同 */
}


}


</style>
