<template>
<ul class="boxsb" v-show="sb">
    <li>
        <ul class="top_list">
            <li @click="bool">  删除 </li>
        </ul>
    </li>
</ul>
</template>

<script setup>
import {  defineProps,ref ,defineEmits }  from 'vue'
const emit =defineEmits(['messgae2'])
defineProps({
  sb: Boolean,
});
const sb2 = ref(false)
const bool = ()=>{
    sb2.value= !sb2.value
    emit('messgae2', sb2.value)
}
</script>

<style lang="scss" scoped>

.boxsb{
  color: white;
  text-align: center;
  width: 180px;
  list-style: none;
 margin-top: 15px;

 height: 50px;

}
     .boxsb>li{
            width: 83px;
   border-radius: 15px;
            /* 设置文本行高 */
            line-height: 50px;
            background-color: #10acef;
        }
      
        .top_list  li:hover{
            background-color:cadetblue;
            border-radius: 15px;
           
        }
</style>