import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import Vue3TouchEvents from "vue3-touch-events";
import Varlet from '@varlet/ui'

import '@varlet/ui/es/style'


createApp(App).use(store).use(router).use(Varlet).use(ElementPlus).use(Vue3TouchEvents).mount('#app')
