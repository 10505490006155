<template>

<div class="wifi"> 

  <!-- wifi 组件 -->
<VueWifi></VueWifi>

</div>

<!--  加载 -->
 <div class="var"  :style="{ transform: 'translateY( ' + boss + 'px )' }" >
  <var-icon name="notebook" color="#2aae67" size="40" v-if=circle />
   <var-loading type="circle" v-else />
  </div>
   
<div id="boss"   :style="{ transform: 'translateY( ' + boss + 'px )' }">
 
  <div class="top_info"
      >
      <div class="top"
      @touchstart="topstart($event)"
      @touchmove="topmove($event)"
      @touchend="topchend($event)"
      >
        <div class="logo"><img src="../../public/www.lclk.top.svg" alt="" /></div>
        <div class="top-text" style="color: rgb(236, 241, 232)">
      感谢您访问我们的网站！当前网站正处于不断开发和完善中，我们致力于为您提供免费，以及最好的用户体验和功能。
      在这个过程中，我们会不断更新和改进网站的各项功能，以确保您能够获得更好的服务。再次感谢您的支持和理解！
        </div>
      </div>
  
    <!--  搜索框 -->
    <VueSearch :input="input" @messgae="fun1"></VueSearch>

    <div class="round_div_all">
      <div class="round_div" @click="More">
        <div class="r-s01">
          <var-icon name="format-list-checkbox" :size="37" color="white" />
        </div>
        <div class="r-s02">
          <!--  更多组件 -->
          更多 
          <VueMore :sb="Moreshow" @messgae2="abc"></VueMore>
        </div>
      </div>

      <div class="round_div" @click="input = !input">
        <div class="r-s01">
          <var-icon name="magnify" :size="37" color="white" />
        </div>
        <div class="r-s02">搜索</div>
      </div>

      <div class="round_div" @click="addView">
        <div class="r-s01">
          <var-icon name="plus" :size="37" color="white" />
        </div>
        <div class="r-s02">添加  </div>
      </div>
    </div>
  </div>

  <var-loading
    type="wave"
    color="#ff9f00"
    size="large"
    description="正在加载中，请稍后"
    v-if="loading"
  />

  <div class="mian01" ref="element">

    <div
      class="square_div"
      v-for="(itme, index) in data"
      :key="itme.id"
      @touchstart="start($event,itme)"
      @touchmove="move($event,itme)"
      @touchend="chend(itme)"
     
    >
      <!-- 滑动删除 -->
      <div class="lcl" >
        <img
          :src="
            itme.img
              ? ` https://m.lclk.top${itme.img}  `
              : 'https://m.lclk.top/images/0ef99843af400bb1e350191edc3bd5f6'
          "
          alt=""
          class="s-s01 left"
          @click="fun(index)"
          :style="{ transform: 'translateX( ' +  itme.num  + 'px )' }"
        />
        <div
          class="right"
          ref="but3"
          :style="{ transform: 'translateX( ' + itme.num + 'px )' }"
          @click="deleteData(itme.id)"
        >
          删除
        </div>
      </div>
      <!-- 滑动删除 -->

    
      <h2>{{ itme.title }}</h2>
      <p class="s-s02" v-html="itme.content"></p>
      <p style="text-align: end; color: #999">
        {{ moment(itme.time).format("YYYY-MM-D") }}
      </p>
      <!-- 删除 -->
      <var-button
        type="danger"
        round
        v-show="divdelete"
        @click="deleteData(itme.id)"
      >
        <var-icon name="window-close" />
      </var-button>
    </div>
  </div>


 <!-- 分页 -->
 <div class="pagination">
  
  <el-pagination
      v-model:current-page="currentPage2"
      v-model:page-size="pageSize2"
      :page-sizes="[10, 20, 30, 40]"
      :background="true"
      layout="total, sizes,  pager"
      :total="parseInt(count)"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />

  </div>


  <!-- 底部 -->
  <footer>
    
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path
        fill="#f3f4f5"
        fill-opacity="1"
        d="M0,128L24,122.7C48,117,96,107,144,138.7C192,171,240,245,288,261.3C336,277,384,235,432,202.7C480,171,528,149,576,160C624,171,672,213,720,192C768,171,816,85,864,74.7C912,64,960,128,1008,181.3C1056,235,1104,277,1152,256C1200,235,1248,149,1296,144C1344,139,1392,213,1416,250.7L1440,288L1440,320L1416,320C1392,320,1344,320,1296,320C1248,320,1200,320,1152,320C1104,320,1056,320,1008,320C960,320,912,320,864,320C816,320,768,320,720,320C672,320,624,320,576,320C528,320,480,320,432,320C384,320,336,320,288,320C240,320,192,320,144,320C96,320,48,320,24,320L0,320Z"
      ></path>
    </svg>
  </footer>
</div>
  <!--返回顶部 -->
  <el-backtop :right="70" :bottom="100" />
</template>

<script setup>
import { ref, onMounted } from "vue";
import { Snackbar } from "@varlet/ui";
import VueMore from "../components/VueMore.vue";
import VueWifi from '../components/VueWifi.vue'
// import {  Finished } from '@element-plus/icons-vue'
import VueSearch from "../components/VueSearch.vue";
import moment from "moment";

const but3 = ref(null);

const loading = ref(true);
const Moreshow = ref(false);
const imgshow = ref(false);
const sb = ref([]);
const images = ref([]);
const data = ref([]);
const element = ref();
const input = ref(false);
const divdelete = ref(false);
const  count = ref(0) // 存储数据总数

// 分页获取数据参数
const pageSize2 = ref(10) // 默显示条数
  const currentPage2 = ref(1) // 默认页码 

const fun = (index) => {
  if (sb.value[index] == " https://m.lclk.top") {
    return;
  }

  images.value[0] = sb.value[index];
  imgshow.value = true;
};

import axios from "axios";
import { useRouter } from "vue-router";


const router = useRouter();

const addView = () => {
  router.push("/addView");
};

const abc = (value) => {
  divdelete.value = value;
};

const More = () => {
  Moreshow.value = !Moreshow.value;
};

//  获取数据
const dataGet = () => {
  axios.get(" https://m.lclk.top",{
    params:{
      pageSize2:pageSize2.value,
      currentPage2:currentPage2.value
    }
  }).then((res) => {
   
    count.value = res.data.data2
    data.value = res.data.data1;
    // console.log( res.data.data1);
  
    data.value.forEach((element, index) => {
      sb.value[index] = ` https://m.lclk.top${data.value[index].img}`;
     
      element['num']= 0 // 向对象添加属性，用于表示滑动按钮的初始距离，然后遍历触摸的三个事件，传入整个对象，通过修改该对象的num，实现滑动显示当前按钮，其他的按钮不现实
     
    });
    loading.value = false;
  });

};

onMounted(() => {
  dataGet();
  
});

//  点击搜索到的数据，滚动到对应数据，并高亮
const fun1 = (id) => {
  // console.log(id);

  //  判断当前页面是否有该笔记
  let target =  data.value.find((element) => element.id == id ? true : false);

   if( !target  ) {
    Snackbar.error('当前页码没有该笔记，请切换页码查询');
     return
   }


  data.value.forEach((e, index) => {

    if (e.id == id) {
      // 判断点击搜索得到数据id，与所有数据中的某个id 是否等于，等于就说明是要滚动到对应数据
      const dom = element.value.children[index]; // 获取要滚动的数据dom
      dom.scrollIntoView({ behavior: "smooth", block: "center" }); // 设置 滚动方式 到的对应数据
      dom.classList.add("domStyle"); // 添加高亮样式

      // 2000 之后移除高亮样式
      setTimeout(() => {
        dom.classList.remove("domStyle");
      }, 2000);

    }

  });
};

//  删除数据
const deleteData = (id) => {

  axios.delete(` https://m.lclk.top/deleteData/${id}`).then((res) => {
    // console.log(res.data);

    if (res.data == "删除成功") {
      Snackbar.success(res.data);
    } else {
      Snackbar.error(res.data);
    }

    dataGet();
  });
};
//  要使用offsetWidth 必须在触摸事件内使用，才有获取元素宽度

// 移动端滑动删除
// const num = ref(0); // 初始距离
const X = ref(); // 记录点击时的位置
const anw = ref(); // 记录按钮的宽度
const Y = ref(); // 记录纵向点击时的位置

//  xy.num 初始距离
// 触摸元素触发
const start = (e,xy) => {
//  当显示当前按钮时，其他按钮不显示
      const  itme = data.value.filter( itme => itme.id != xy.id )
  itme.forEach ( (itme) =>{
    if( itme.num == -100 ){
      itme.num = 0
    }
  } )

  // 把点击的位置赋值给对应变量
  anw.value = but3.value[0].offsetWidth;
      

  X.value = e.targetTouches[0].clientX;

  Y.value = e.targetTouches[0].clientY;
};

// 触摸移动元素触发
const move = (e,xy) => {  // 用最新移动的位置  ——  点击的位置 = 移动的位置
  

  X.value = e.targetTouches[0].clientX - X.value;
  Y.value = e.targetTouches[0].clientY - Y.value;

  if (Math.abs(X.value) < Math.abs(Y.value)) { // 判断是否是纵向滑动，变成正数来判断，如果横向滑动的位置，小于垂直滑动的位置，就说明在垂直滑动，因为垂直滑动都比横向滑动的位置大，反之就是在横向滑动
    // 就不执行下面代码
    return;
  }

  if(e.cancelable){
    e.preventDefault(); // 禁止滑动时，浏览器滚动条移动
  }

  xy.num = X.value; // 把横向滑动的位置给xy.num

// console.log(anw.value);
  // 限制移动
  if ( xy.num > 0) {   // 向右滑动的限制
    xy.num = 0;

  } else {

    if ( xy.num < -anw.value / 2) {   // 向左滑动的限制
      xy.num = -anw.value;
    }
  }

};
// 触摸松开元素触发
const chend = (xy) => {
  // 松开时，再进行当前的位置进行判断，是否显示按钮
 
  if (Math.abs(xy.num) < anw.value / 2) {
    xy.num = 0;
  } else {
    xy.num = -anw.value;
  }
};
//  移动端下拉 页面，刷新页面
const boss = ref(0)  // 记录 初始点击的位置
const  topY= ref()  // 记录Y的位置
const topX = ref()  // 记录X的位置
const circle = ref(true)
const topstart = (e)=>{
       topX.value = e.targetTouches[0].clientX;
      topY.value = e.changedTouches[0].clientY
   
}
const topmove = (e)=>{
  
      const Y = e.changedTouches[0].clientY - topY.value 
      const X = e.changedTouches[0].clientX - topX.value 
    
   if( Math.abs(X)  > Math.abs(Y)  ) return

  //      if(e.cancelable){
  //   e.preventDefault(); // 禁止滑动时，浏览器滚动条移动
  // }

      boss.value= Y
      if( boss.value > 70 ){
       circle.value = false
       boss.value= 70
      }

}
const topchend = ()=>{
      // console.log(e.changedTouches[0].clientY);
      if( boss.value < 70 ){
       boss.value = 0
       return
      }
     
        dataGet()
        boss.value = 0
      circle.value = true
      Snackbar.success('刷新成功');
      }

  //   分页
  const handleSizeChange = (val) => {  // 选择每页显示条数触发

   currentPage2.value= 1
    pageSize2.value = val
    dataGet() // 获取最新的页码
}
const handleCurrentChange = (val) => { //  点击页码触发
 
  currentPage2.value = val

   dataGet()  // 获取最新的条数
}





</script> 

<style lang="scss" scoped>
.wifi{
  width: 50px;
  height: 50px;
  position: absolute;
  left:6%;
  top:5%;
  z-index: 999;
  background:rgb(187, 173, 173)606;
  display: grid;
  place-items: center;
}
#boss{
  transition: all 0.3s;
}
.pagination{
  display: grid;
  place-items: center;
  margin-top:30px;
}
.var{
  margin-left: auto;
  margin-right: auto;
  display: grid;
  place-items: center;
  width:50px;
  height: 50px;
  transition: all 0.3s;
  margin-top:-70px;
}
.lcl {
  height: 300px;
  position: relative;
  overflow: hidden;
}
.domStyle {
  background: #99999975 !important;
  color: white !important;
}
.el-input2 {
  width: 18%;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 30px;
  right: 50px;
}
::v-deep .el-input {
  outline: none;
}

* {
  margin: 0;
  padding: 0;
} /* 通配符 删除浏览器默认值 */

ul {
  list-style: none; /* 取消项目符号样式 */
}

body {
  overflow-x: hidden;
  background-color: rgba(250, 250, 250, 1);
  font-family: "Helvetica Neue", "Helvetica", "Microsoft Yahei", sans-serif; /* 字体集 */
}

.left {
  position: absolute;
  left: 0;
  transition: all 0.5s;
}
.right {
  position: absolute;
  right: -100px;
  transition: all 0.5s;
  height: 300px;
  width: 100px;
  text-align: center;
  line-height: 300px;
  font-size: 20px;
  background: red;
  color: white;
}

.square_div {
  padding: 10px;
  height: auto;
  box-sizing: border-box;
}

@media (min-width: 800px) {
  /* >=601的设备*/

  .top_info {
    width: 100%;
    height: 600px;
    position: relative;
  }

  .top_info:after {
    background-color: #2aae67;

    content: "";
    width: 160%;
    height: 650px;
    position: absolute;
    left: -30%;
    top: 0;
    z-index: -1;
    border-radius: 0 0 50% 50%;
  }
  .top_info .top-text {
    height: 130px;
    width: 620px;
    margin-top: 40px;
    margin-right: auto;
    margin-left: auto;
    font-size: 0.87em;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: #f2f2f2;
    line-height: 32px;
    color: #f2f2f2;
    padding-top: 18px;
  }
  .top_info .round_div_all {
    height: 100px;
    width: 600px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 5px;
    display: -webkit-flex; /* 定义内部为弹性布局 */
    display: flex; /* 定义内部为弹性布局 */
    flex-wrap: wrap; /* 让弹性盒元素在需要的时候拆列 */
    justify-content: space-around; /* （横轴）方向上均匀排列每个元素 */
  }
  .mian01 {
    width: 1500px;
    // min-height: 400px;

    margin-top: 40px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 40px;
    padding-bottom: 40px;
    overflow: hidden; /* 防止塌陷 */
    display: -webkit-flex; /* 定义内部为弹性布局 */
    display: flex; /* 定义内部为弹性布局 */
    flex-wrap: wrap; /* 让弹性盒元素在需要的时候拆列 */

    justify-content: space-between; /* （横轴）方向上均匀排列每个元素 */
  }
  .square_div {
    // float: left;
    // min-height: 450px;
    height: auto;
    width: 450px;
    margin-top: 30px;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0px 0px 5px 5px rgba(152, 152, 152, 0.07); /* 设置一个阴影 颜色 透明度 */
    cursor: pointer; /* 鼠标在此处的效果为“小手”样式 */
    margin-right: auto;
    margin-left: auto;
  }

  .square_div:hover {
   
    
  }
}
@media (max-width: 1300px) {
  /*响应式 <=600的设备使用如下css样式表 */
  .top_info {
    width: 100%;
    height: 750px;
    background-color: #2aae67;
    border-bottom-left-radius: 60% 15%;
    border-bottom-right-radius: 60% 15%;
  }
  .top_info .top-text {
    height: 130px;
    width: 80%;
    margin-top: 40px;
    margin-right: auto;
    margin-left: auto;
    font-size: 0.87em;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: #f2f2f2;
    line-height: 32px;
    color: #f2f2f2;
    padding-top: 18px;
  }
  .top_info .round_div_all {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 40px;
    display: -webkit-flex; /* 定义内部为弹性布局 */
    display: flex; /* 定义内部为弹性布局 */
    flex-wrap: wrap; /* 让弹性盒元素在需要的时候拆列 */
    justify-content: space-around; /* （横轴）方向上均匀排列每个元素 */
  }
  .mian01 {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 40px;
    padding-bottom: 40px;
    overflow: hidden; /* 防止塌陷 */
    display: -webkit-flex; /* 定义内部为弹性布局 */
    display: flex; /* 定义内部为弹性布局 */
    flex-wrap: wrap; /* 让弹性盒元素在需要的时候拆列 */
    justify-content: space-around; /* （横轴）方向上均匀排列每个元素 */
  }
  .square_div {
    // min-height: 450px;
    height: auto;
    width: 450px;
    margin-top: 30px;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0px 0px 5px 5px rgba(152, 152, 152, 0.07); /* 设置一个阴影 颜色 透明度 */
    cursor: pointer; /* 鼠标在此处的效果为“小手”样式 */
    margin-right: auto;
    margin-left: auto;
  }
  .el-input2 {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 30px;
    right: 10px;
  }
}

@media (max-width: 800px) {
  /* 小于800 */
  .el-input2 {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 30px;
    left: 0;
  }
  .mian01 {
    // min-height: 400px;
    width: 90%;
    margin-right: auto;
    margin-left: auto;

    padding-top: 40px;
    padding-bottom: 40px;
    overflow: hidden; /* 防止塌陷 */
    display: -webkit-flex; /* 定义内部为弹性布局 */
    display: flex; /* 定义内部为弹性布局 */
    flex-wrap: wrap; /* 让弹性盒元素在需要的时候拆列 */
    justify-content: space-around; /* （横轴）方向上均匀排列每个元素 */
  }

  .square_div {
    // float: left;
    // min-height: 450px;
    height: auto;
    width: 450px;
    margin-top: 30px;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0px 0px 5px 5px rgba(152, 152, 152, 0.07); /* 设置一个阴影 颜色 透明度 */
    cursor: pointer; /* 鼠标在此处的效果为“小手”样式 */
    margin-right: auto;
    margin-left: auto;
  }
}

.top_info .login {
  height: 22px;
  width: 45px;
  margin-top: 1%;
  float: left;
  margin-left: 91%;
  font-size: 0.9em;
  letter-spacing: 3px;
}
.top_info .logo {
  height: 64px;
  width: 200px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 135px;
  padding-top: 100px;
}

.top_info .round_div_all .round_div {
  float: left;
  height: 94px;
  width: 99px;
  margin-top: 30px;
  border-radius: 50%;
  background-color: #39b472;
  cursor: pointer; /* 鼠标在此处的效果为“小手”样式 */
  margin-left: 25px;
  margin-right: 25px;
}
.top_info .round_div_all .round_div:hover {
  background-color: #4aba7e;
  transition-duration: 0.3s; /* 缩放的过渡时间 */
}
.top_info .round_div_all .round_div .r-s01 {
  height: 40px;
  width: 40px;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
}
.top_info .round_div_all .round_div .r-s02 {
  height: 25px;
  width: 90px;

  margin-right: auto;
  margin-left: auto;
  font-size: 0.9em;
  color: #f2f2f2;
  text-align: center;
}

.s-s01 {
  width: 100%;
  height: 300px;
  object-fit: scale-down; // 解决变形，但会不能铺满
}

 .s-s02 {
  width: 100%;
  margin-top: 10px;
  font-size: 0.9em;
  color: #1a1a1a;
  margin-right: auto;
  margin-left: auto;
  white-space: pre-wrap;
  height: auto;
  // display: inline-block;
  display: flex;
}

a:link {
  color: #ffffff;
  text-decoration: none;
}
a:visited {
  text-decoration: none;
  color: #ffffff;
}
a:hover {
  text-decoration: none;
  color: #ececec;
}
a:active {
  text-decoration: none;
}
</style>