<template>
  <div
    @click="div ? fun($event) : ''"
    @drop="drop"
    @dragenter="genter"
    @dragleave="leave"
    @dragover="gover"
    :class="{ hover: style }"
    class="sb"
  >
    <img :src="src" alt="" v-if="img" />
    <var-icon name="image-outline" :size="100" color="blue" v-else />
    <var-icon
      name="trash-can-outline"
      id="icon"
      :size="30"
      color="red"
      @click="fun2"
    />
    <var-icon name="image-outline" :size="30" id="icon2" @click="preview"  v-show="icon" />
  </div>
  <input type="file" name="" id="" ref="inp" @change="chenge" />
   <Vuepreview v-if="bigImg" :message="data" @son="son"></Vuepreview>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import  Vuepreview from '../components/Vuepreview.vue'
const emit = defineEmits(["get-message"]);
import { Snackbar } from "@varlet/ui";
const img = ref(false);
const src = ref();
const inp = ref(null);
const style = ref(false);
const div = ref(true); // 控制 点击时预览图时，不会触发input的file 默认行为
const bigImg = ref(false); // 控制预览图显示
const icon = ref(false)
const data = ref({s:1})
// 封装文件转换图片地址
function imgdata(flie2) {
  console.log(flie2);
  if (flie2) {
    let flie = URL.createObjectURL(flie2);
    src.value = "";
    src.value = flie;
    icon.value = true
    img.value = true;
  }
}

const fun = (e) => {
  console.log(div.value);
  if (e.target != e.currentTarget.children[1]) {
    inp.value.click();
  }
};
const chenge = () => {
  imgdata(inp.value.files[0]);

  if (inp.value.files[0]) {
    emit("get-message", inp.value.files[0]);
  }
};

// 当拖拽元素释放，在接收元素时触发
const drop = (e) => {
  e.preventDefault();

  console.log(e.dataTransfer.files[0]);

  if (e.dataTransfer.files.length != 1) {
    Snackbar.error("只能上传一张图片");
    return;
  }

  imgdata(e.dataTransfer.files[0]);
  emit("get-message", e.dataTransfer.files[0]);
  style.value = false;
};

// 当拖拽元素，进入接受元素的上方时触发
const genter = (e) => {
  e.preventDefault();
};

//当拖拽元素，没有释放，离开时触发
const leave = () => {
  style.value = false;
};

// 当拖拽元素，在接受元素上方时触发，会一直触发
const gover = (e) => {
  e.preventDefault();
  style.value = true;
};

// 注意：ondragover  和  ondrop 事件，必须一起使用，不然ondrop 事件，无效

const fun2 = () => {
  img.value = false;
  src.value = "";
  icon.value = false
  emit("get-message", "");
};

const preview = ()=>{
    bigImg.value = true
    div.value = false
    // icon.value = true
     data.value = {
     icon: icon.value,
     src: src.value
     }
}
const son = (value)=>{
     console.log(value);
     div.value = value.divValue
     bigImg.value = value.bigImgValue
}

</script>

<style  scoped>
#icon2 {
  position: absolute;
  right: 50px;
  top: 15px;
}
.sb {
  width: 300px;
  height: 400px;
  border: 1px solid #999;
  display: grid;
  place-items: center;

  position: relative;

  border-radius: 5px;
}
input {
  display: none;
}
.sb img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  border-radius: 5px;
}
#icon {
  position: absolute;
  right: 10px;
  top: 15px;
}
.hover {
  border: 1px solid blue;
}


</style>