<template>
  <div class="box">
    <div class="icon" v-show="icon">
      <var-icon name="image-outline" size="30" color="red" @click="img" />
    </div>
    <var-uploader
      v-model="files"
      @after-read="handleAfterRead"
      maxlength="1"
      :previewed="false"
      @remove="fun"
    />
  </div>

  <div class="sb" v-show="bool" @click="none($event)">
    <img
      :src="src"
      alt=""
      @touchend="end($event)"
      @touchmove="chmove($event)"
      @touchstart="chstart($event)"
    :style="{transform: 'scale( ' + S + ' )'}"
    />
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";
const bool = ref(false);
const icon = ref(false);
const emit = defineEmits(["get-message"]);
const files = ref();
const src = ref();


const handleAfterRead = () => {
  icon.value = true;

  src.value = URL.createObjectURL(files.value[0].file);

  emit("get-message", files.value[0].file);
};

const fun = () => {
  icon.value = false;
  emit("get-message", "");
};

const img = () => {
  bool.value = true;
};

const none = (e) => {
  // console.log(e.target);

  if (e.target == e.currentTarget) {
    bool.value = false;
  }
};

// const one1 = ref(); // 记录点击上一次点击，默认为空
// const throttle = ref();
// 双击缩放图片
const end = () => {

  // console.log("离开");


};
const chmove = () => {
  // console.log(e.touches);
};
const S = ref(1)
const time = ref(0) // 记录上一次点击的时间
const chstart = (e) => {

       // 双击
      if( e.touches.length != 1 ) return  // 判断是单指点击，如果不是就不执行下一步
       
        const newtime = Date.now() // 记录最新点击时间

       
         if( time.value > 0 && newtime - time.value < 300 ){
         
          console.log('双击');     
          S.value =  S.value != 1.5 ? 1.5 : 1


         }else{ 
      
          console.log('单击');
          time.value = Date.now()        
         }
 

};


</script>

<style scoped>
::v-deep .var-elevation--2 {
  height: 300px;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.box {
  position: relative;
}

.icon {
  z-index: 999;
  position: absolute;
  top: 0;
  right: 30px;
}
.sb {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 200%;
  z-index: 999;
  background: #430303c0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
img {
  margin-top: 200px;
  width: 100%;
  height: atuo;
  transition: all 0.3s;
  /* ,transformOrigin: 'left' */
}
</style>