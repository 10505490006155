<template>
  <div class="el-input2" v-show="input" >
    <el-input
      v-model="value6"
      placeholder="请输入要搜索的笔记"
      :prefix-icon="Search"
      size="large"
      input-style=" outline: none"
      @input="change"
      :autofocus="true"
  
    />

    <!--  显示搜索结果 -->
    <ul v-show="show">
        <!-- 加载显示组件 -->
            <li class="loading"  v-if="loading">
                <var-loading type="wave" size="large" description="正在加载中"/>
            </li>
          
      <li   v-for="itme in data" :key="itme.id" class="li" v-else @click="fun(itme.id)" >
        <h4 v-html="itme.title"></h4>
        <p v-html="itme.content" class="p"></p>
      </li>
<!-- 没有查找到对应笔记 -->
     <li v-show="empty">
        <el-empty description="没有查找到笔记" />
      </li>

    </ul>
  </div>
</template>

<script setup>
import axios from "axios";
import { Search } from "@element-plus/icons-vue";
import { ref, defineProps,defineEmits } from "vue";
const value6 = ref()
const emit =defineEmits(['messgae'])
const data = ref([])
const show = ref(false)
const loading = ref(true)
const empty = ref(false)

defineProps({
  input: Boolean,
});
const change = () => {
//  搜索

  if (value6.value != '') {
    axios
      .get("https://m.lclk.top/Search", {
        params: {
          value: value6.value,
        },
      })
      .then((res) => {
        show.value = true
        loading.value= false
        data.value = []  //  先把上次数据清除
        data.value = res.data; // 再获取数据

        //  高亮搜索的关键字
       value6.value.split('').forEach( element => {
          data.value.forEach( itme =>{              
               let htmltitle = `<span style="color:red">${ element }</span>`
               let htmlcontent = `<span style="color:red">${ element }</span>`
               let str = new RegExp(element,'gi')         
               itme.title = itme.title.replace( str ,htmltitle)
                itme.content = itme.content.replace( str ,htmlcontent)
          
          })
       });
        empty.value = data.value.length == 0 ? true : false // 当数据长度为0，就显示没有对应笔记，或者有数据，就不显示
        
      });
     
  }else{
    show.value = false
  }
   
};
const fun = (id)=>{

emit('messgae',id)
show.value = false
}


</script>

<style lang="scss" scoped>
ul {
  width: 100%;
 min-height: 100px;
  background: white;
  list-style-type: none;
  padding: 10px;
  box-sizing: border-box;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  max-height: 500px;
  overflow: auto;
  cursor: pointer;
}
ul .li {
  margin-top: 10px;
 min-height: 47px;
  padding: 10px;
  font-size: 15px;
}
ul .li:hover {
  background: #bcb1b165;
}
.loading {
    height: 100%;
    box-sizing: border-box;
    padding-top: calc(50% - 48.5px);
}
.p{
  height: 100%;
  width: 100%;
  //  把多余的文本省略，换成...
  display: -webkit-box;
    -webkit-line-clamp: 2; /* 设置最大行数 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
