<template >
  <var-app-bar title="主页">
    <template #left>
      <var-button color="transparent" text-color="#fff" round text @click="top">
        <var-icon name="chevron-left" :size="24" />
      </var-button>
    </template>

    <template #right>
      <var-icon name="menu" :size="24" @click="show = true" />
    </template>
  </var-app-bar>

  <var-input placeholder="标题" v-model="obj.bos" :autofocus="true" />

  <div class="box">
    <img
      src="https://m.lclk.top/images/d5b421461c8daf805da60fad68b8152b"
      alt=""
    />

    <!-- PC 端 上次图片 -->
    <div v-show="resize" class="resize">
      <VueUploaderPC @get-message="sb2"></VueUploaderPC>
    </div>

    <!-- 移动端 上传图片 -->
    <div v-show="resize2" class="resize">
      <VueDialogVue @get-message="sb2"></VueDialogVue>
    </div>

    <img
      src="https://m.lclk.top/images/74ee4895feb94b575373105835bb45cb"
      alt=""
    />
  </div>

  <var-input
    variant="outlined"
    placeholder="内容"
    textarea
    v-model="obj.text"
    :rows="20"
  ></var-input>

  <!-- 提示组件 -->
  <var-snackbar v-model:show="show"> 非常抱歉，当前功能还在开发 </var-snackbar>
</template>
<script setup>
import { ref, reactive, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
import VueDialogVue from "../components/VueUploader.vue";
import VueUploaderPC from "../components/VueUploaderPC.vue";

import axios from "axios";

const show = ref(false);
const box = ref();
let bool = ref(true);

const obj = reactive({
  imgdata: "",
  bos: "",
  text: "",
});

const sb2 = (value) => {
  box.value = value;
};



const top = () => {
  // console.log(box.value);
  if (box.value) {
    obj.imgdata = box.value;
  }

  const fd = new FormData();

  for (let i in obj) {
    fd.append("flie", obj[i]);
  }

  if (obj.bos != "" && obj.text != "") {
    axios.post(" https://m.lclk.top/users", fd, {
      "content-Type": "multipart/form-data",
    });

    bool.value = false;
  }

  router.push("/");
};

onBeforeUnmount(() => {
  if (bool.value) {
    if (box.value) {
      obj.imgdata = box.value;
    }

    const fd = new FormData();

    for (let i in obj) {
      fd.append("flie", obj[i]);
    }

    if (obj.bos != "" && obj.text != "") {
      axios.post("https://m.lclk.top/users", fd, {
        "content-Type": "multipart/form-data",
      });
    }
  }
});

const resize = ref(true);

const resize2 = ref(false);

handleWindowResize();

// // 定义一个函数来处理窗口宽度变化
function handleWindowResize() {
  const newwidth = window.innerWidth;
  console.log(newwidth);

  if (newwidth > 1150) {
    resize.value = true;
    resize2.value = false;
  } else {
    resize2.value = true;
    resize.value = false;
  }
}
window.addEventListener("resize", handleWindowResize);

// 添加resize事件监听器
</script>

<style scoped>
#icon {
  margin-right: 10px;
}
.box {
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}

img {
  width: 40%;
  pointer-events: none;
}
@media (max-width: 1200px) {
  .box {
    display: flex;

    flex-wrap: wrap;
  }

  img {
    width: 100%;
  }
}
.resize {
  width: 100%;
  display: grid;
  place-items: center;
}

</style>