<template>
    <var-icon :name="name" :color="color" :size="30" :transition="1000"/>
</template>

<script setup>
import { ref } from "vue";
import { Snackbar } from "@varlet/ui";
// 检测网络状态
const color = ref('green')
const name = ref('wifi')
//封装 函数。检测当前网络是否在线
function getNavigator() {
  let Navigator = {};
  if( navigator.onLine ){  
    Navigator={
      type:navigator.connection.effectiveType,
      rtt:navigator.connection.rtt,
      downlink:navigator.connection.downlink
    }
    } else {
      Navigator = {
        type:'offline'
      }
    }
    return Navigator 
}

// console.log(getNavigator());

// 封装函数，判断当前网络状态，并返回对应wifi 颜色，提示用户
function ifNavigator() {
    const Navigator = getNavigator()
    // console.log(Navigator.type);
     switch (Navigator.type) {
        case  '4g':
        name.value= 'wifi'
            color.value = 'green'
            break;
        case  '3g':
        name.value= 'wifi'

            color.value = 'yellow'
            break;
        case  '2g':
           name.value= 'wifi'
            color.value = 'red'
            Snackbar.warning('当前网络较慢，请耐心等候')
            break;
        default:

            name.value = 'information-outline'
               
                  color.value = 'red'
                  Snackbar.error('当前网络不在状态');
              
            break;
     }
}

// ifNavigator() 
//  通过以下事件，调用封装函数，获取最新的网络状态 
navigator.connection.addEventListener('change',ifNavigator) // 当网络发生改变时 触发
window.addEventListener('online',getNavigator) // 从离线到在线 触发
window.addEventListener('onffline',getNavigator) // 从在线到离线 触发
</script>

<style lang="scss" scoped>

</style>
